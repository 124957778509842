import { SegmentName, ReviewsSettingsTabName } from './types';
import { Sorting } from '~commons/settings/constants/sorting';
import { FormEffect } from '../store/reviews/reviews/review-state-types';

export const OPEN_TAB_EVENT = 'open-tab-event' as const;
export const OPEN_SEGMENT_EVENT = 'open-segment-event' as const;
export const UPDATE_DEFAULT_SORTING_EVENT = 'update-default-sorting-event' as const;
export const UPDATE_PAGE_SIZE_EVENT = 'update-page-size-event' as const;
export const SET_FORM_EFFECT_EVENT = 'set-form-effect-event' as const;

export const openTab = (tabName: ReviewsSettingsTabName) => ({
  type: OPEN_TAB_EVENT,
  payload: { tabName },
});

export const openSegment = (segmentName: SegmentName) => ({
  type: OPEN_SEGMENT_EVENT,
  payload: { segmentName },
});

export const updateDefaultSorting = (sorting: Sorting) => ({
  type: UPDATE_DEFAULT_SORTING_EVENT,
  payload: { sorting },
});

export const updatePageSize = (size: number) => ({
  type: UPDATE_PAGE_SIZE_EVENT,
  payload: { size },
});

export const setFormEffect = (state: FormEffect) => ({
  type: SET_FORM_EFFECT_EVENT,
  payload: state,
});

export type ReviewsSettingsEvent =
  | ReturnType<typeof openTab>
  | ReturnType<typeof openSegment>
  | ReturnType<typeof updateDefaultSorting>
  | ReturnType<typeof updatePageSize>
  | ReturnType<typeof setFormEffect>;
