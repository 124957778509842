import { Ordering, Review } from '../../../controller/lib/reviews-api-types';
import { ReviewContent } from '../reviews/reviews/review-state-types';
import { InvalidFields } from '~reviews/Widget/components/review-form/review-form-state';

export const BI_APP_LOADED = 'bi/APP_LOADED' as const;
export const BI_APP_IS_IN_VIEWPORT = 'bi/BI_APP_IS_IN_VIEWPORT' as const;
export const BI_CLICK_LEAVE_REVIEW_BTN = 'bi/CLICK_LEAVE_REVIEW_BTN' as const;
export const BI_REVIEW_PUBLISH_SUCCESS = 'bi/REVIEW_PUBLISH_SUCCESS' as const;
export const BI_CLICK_REPLY_BTN = 'bi/CLICK_REPLY_BTN' as const;
export const BI_CLICK_HELPFUL_BTN = 'bi/CLICK_HELPFUL_BTN' as const;
export const BI_CLICK_HELPFUL_BTN_SUCCESS = 'bi/CLICK_HELPFUL_BTN_SUCCESS' as const;
export const BI_CLICK_SHOW_MORE_BTN = 'bi/CLICK_SHOW_MORE_BTN' as const;
export const BI_OPEN_SORTING = 'bi/OPEN_SORTING' as const;
export const BI_OPEN_FILTERING = 'bi/OPEN_FILTERING' as const;
export const BI_CHANGE_SORTING = 'bi/CHANGE_SORTING' as const;
export const BI_CHANGE_FILTERING = 'bi/CHANGE_FILTERING' as const;
export const BI_OPEN_REVIEW_ACTIONS = 'bi/OPEN_REVIEW_ACTIONS' as const;
export const BI_OPEN_REPLY_ACTIONS = 'bi/OPEN_REPLY_ACTIONS' as const;
export const BI_CLICK_SHOW_MY_REVIEW = 'bi/CLICK_SHOW_MY_REVIEW' as const;
export const BI_SELECT_REVIEW_ACTION = 'bi/SELECT_REVIEW_ACTION' as const;
export const BI_SELECT_REPLY_ACTION = 'bi/SELECT_REPLY_ACTION' as const;
export const BI_REVIEW_DELETED = 'bi/REVIEW_DELETED' as const;
export const BI_SHOW_FILTERED_TO_ZERO_EMPTY_STATE = 'bi/SHOW_FILTERED_TO_ZERO_EMPTY_STATE' as const;
export const BI_SHOW_ZERO_REVIEWS_EMPTY_STATE = 'bi/SHOW_ZERO_REVIEWS_EMPTY_STATE' as const;
export const BI_SHOW_FAILED_TO_LOAD_EMPTY_STATE = 'bi/SHOW_FAILED_TO_LOAD_EMPTY_STATE' as const;
export const BI_SHOW_FALLBACK_STATE = 'bi/BI_SHOW_FALLBACK_STATE' as const;
export const BI_CLOSE_MEDIA_MODAL = 'bi/CLOSE_MEDIA_MODAL' as const;
export const BI_OPEN_MEDIA_MODAL = 'bi/OPEN_MEDIA_MODAL' as const;
export const BI_CLICK_PAGINATION_BTN = 'bi/CLICK_PAGINATION_BTN' as const;
export const BI_CLICK_SHOW_ALL_REVIEWS_BTN = 'bi/CLICK_SHOW_ALL_REVIEWS_BTN' as const;
export const BI_CLICK_PUBLISH_REVIEW = 'bi/CLICK_PUBLISH_REVIEW' as const;
export const BI_FORM_VALIDATION_ERROR = 'bi/FORM_VALIDATION_ERROR' as const;
export const BI_CLICK_PUBLISH_REPLY = 'bi/CLICK_PUBLISH_REPLY' as const;
export const BI_CANCEL_CREATING_REVIEW = 'bi/CANCEL_CREATING_REVIEW' as const;
export const BI_CANCEL_CREATING_REPLY = 'bi/CANCEL_CREATING_REPLY' as const;
export const BI_REPLY_DELETED = 'bi/REPLY_DELETED' as const;
export const BI_MEDIA_UPLOADED = 'bi/MEDIA_UPLOADED' as const;
export const BI_MEDIA_DELETED = 'bi/MEDIA_DELETED' as const;
export const BI_REPLY_PUBLISH_SUCCESS = 'bi/REPLY_PUBLISH_SUCCESS' as const;
export const BI_SHOW_PENDING_REVIEW_MODAL = 'bi/SHOW_PENDING_REVIEW_MODAL' as const;
export const BI_REVIEW_SUBMITTED_FOR_APPROVAL = 'bi/BI_REVIEW_SUBMITTED_FOR_APPROVAL' as const;
export const BI_CLICK_CLOSE_PENDING_REVIEW_MODAL = 'bi/CLICK_CLOSE_PENDING_REVIEW_MODAL' as const;
export const BI_FOCUS_FORM_BODY = 'bi/FOCUS_FORM_BODY' as const;

export const biAppLoaded = () => ({
  type: BI_APP_LOADED,
});

export const biAppIsInViewport = () => ({
  type: BI_APP_IS_IN_VIEWPORT,
});

export const biClickLeaveReviewBtn = (payload: { origin: 'product_page' | 'empty_state' }) => ({
  type: BI_CLICK_LEAVE_REVIEW_BTN,
  payload,
});

export const biReviewPublishSuccess = (payload: { review: Review; isEdited?: boolean }) => ({
  type: BI_REVIEW_PUBLISH_SUCCESS,
  payload,
});

export const biClickReplyBtn = (payload: { reviewId: string }) => ({
  type: BI_CLICK_REPLY_BTN,
  payload,
});

export const biClickHelpfulBtn = (payload: {
  action: 'upvote' | 'downvote';
  reviewId: string;
}) => ({
  type: BI_CLICK_HELPFUL_BTN,
  payload,
});

export const biClickHelpfulBtnSuccess = (payload: {
  action: 'upvote' | 'downvote';
  reviewId: string;
}) => ({
  type: BI_CLICK_HELPFUL_BTN_SUCCESS,
  payload,
});

export const biClickShowMoreBtn = (payload: {
  isShowMore: boolean;
  origin: 'main_screen' | 'media_dialog';
  reviewId: string;
}) => ({
  type: BI_CLICK_SHOW_MORE_BTN,
  payload,
});

export const biOpenSorting = () => ({
  type: BI_OPEN_SORTING,
});

export const biOpenFiltering = () => ({
  type: BI_OPEN_FILTERING,
});

export const biChangeSorting = (payload: { ordering: Ordering }) => ({
  type: BI_CHANGE_SORTING,
  payload,
});

export const biChangeFiltering = (payload: { rating: string; origin: 'dropdown' | 'bars' }) => ({
  type: BI_CHANGE_FILTERING,
  payload,
});

export const biOpenReviewActions = (payload: { reviewId: string }) => ({
  type: BI_OPEN_REVIEW_ACTIONS,
  payload,
});

export const biOpenReplyActions = (payload: { reviewId: string }) => ({
  type: BI_OPEN_REPLY_ACTIONS,
  payload,
});

export const biClickShowMyReview = () => ({
  type: BI_CLICK_SHOW_MY_REVIEW,
});

export const biSelectReviewAction = (payload: {
  action: 'copy_link' | 'edit' | 'delete';
  reviewId: string;
}) => ({ type: BI_SELECT_REVIEW_ACTION, payload });

export const biSelectReplyAction = (payload: { action: 'edit' | 'delete'; reviewId: string }) => ({
  type: BI_SELECT_REPLY_ACTION,
  payload,
});

export const biReviewDeleted = (payload: { reviewId: string }) => ({
  type: BI_REVIEW_DELETED,
  payload,
});

export const biShowFilteredToZeroEmptyState = (rating: number) => ({
  type: BI_SHOW_FILTERED_TO_ZERO_EMPTY_STATE,
  payload: { description: `filtered_to_empty_${rating}_stars` },
});

export const biShowZeroReviewsEmptyState = () => ({
  type: BI_SHOW_ZERO_REVIEWS_EMPTY_STATE,
  payload: { description: 'zero_reviews_empty_state' },
});

export const biShowFailedToLoadEmptyState = () => ({
  type: BI_SHOW_FAILED_TO_LOAD_EMPTY_STATE,
  payload: { description: 'failed_to_load_empty_state' },
});

export const biShowFallbackState = () => ({
  type: BI_SHOW_FALLBACK_STATE,
  payload: { description: 'fallback_state' },
});

export const biCloseMediaModal = (payload: { reviewId: string }) => ({
  type: BI_CLOSE_MEDIA_MODAL,
  payload,
});

export const biOpenMediaModal = (payload: { reviewId: string }) => ({
  type: BI_OPEN_MEDIA_MODAL,
  payload,
});

export const biClickPaginationBtn = (payload: { action: 'next' | 'previous' }) => ({
  type: BI_CLICK_PAGINATION_BTN,
  payload,
});

export const biClickShowAllReviewsBtn = () => ({
  type: BI_CLICK_SHOW_ALL_REVIEWS_BTN,
});

export const biClickPublishReview = (payload: {
  review: { content: ReviewContent; id?: string };
  isEdited?: boolean;
}) => ({
  type: BI_CLICK_PUBLISH_REVIEW,
  payload,
});

export const biFormValidationError = (payload: {
  reviewId?: string;
  missingRequiredFields: InvalidFields;
}) => ({
  type: BI_FORM_VALIDATION_ERROR,
  payload,
});

export const biClickPublishReply = (payload: { reviewId: string; isEdited?: boolean }) => ({
  type: BI_CLICK_PUBLISH_REPLY,
  payload,
});

export const biCancelCreatingReview = (payload: {
  review: { content: ReviewContent; id?: string };
  isEdited?: boolean;
}) => ({
  type: BI_CANCEL_CREATING_REVIEW,
  payload,
});

export const biCancelCreatingReply = (payload: { reviewId: string; isEdited?: boolean }) => ({
  type: BI_CANCEL_CREATING_REPLY,
  payload,
});

export const biReplyDeleted = (payload: { reviewId: string }) => ({
  type: BI_REPLY_DELETED,
  payload,
});

export const biMediaUploaded = (payload: { imageCount: number; videoCount: number }) => ({
  type: BI_MEDIA_UPLOADED,
  payload,
});

export const biMediaDeleted = () => ({
  type: BI_MEDIA_DELETED,
});

export const biReplyPublishSuccess = (payload: {
  reviewId: string;
  replyText: string;
  isEdited: boolean;
}) => ({
  type: BI_REPLY_PUBLISH_SUCCESS,
  payload,
});

export const biReviewSubmittedForApproval = (payload: { reviewId: string }) => ({
  type: BI_REVIEW_SUBMITTED_FOR_APPROVAL,
  payload,
});

export const biShowPendingReviewModal = (payload: { reviewId: string }) => ({
  type: BI_SHOW_PENDING_REVIEW_MODAL,
  payload,
});

export const biClickClosePendingReviewModal = (payload: {
  origin: 'ok' | 'x';
  reviewId: string;
}) => ({
  type: BI_CLICK_CLOSE_PENDING_REVIEW_MODAL,
  payload,
});

export const biFocusFormBody = () => ({
  type: BI_FOCUS_FORM_BODY,
});

type BiAppLoaded = ReturnType<typeof biAppLoaded>;
type BiAppIsInViewport = ReturnType<typeof biAppIsInViewport>;
type BiClickLeaveReviewBtn = ReturnType<typeof biClickLeaveReviewBtn>;
type BiReviewPublishSuccess = ReturnType<typeof biReviewPublishSuccess>;
type BiClickReplyBtn = ReturnType<typeof biClickReplyBtn>;
export type BiClickHelpfulBtn = ReturnType<typeof biClickHelpfulBtn>;
export type BiClickHelpfulBtnSuccess = ReturnType<typeof biClickHelpfulBtnSuccess>;
type BiClickShowMoreBtn = ReturnType<typeof biClickShowMoreBtn>;
type BiOpenSorting = ReturnType<typeof biOpenSorting>;
type BiOpenFiltering = ReturnType<typeof biOpenFiltering>;
type BiChangeSorting = ReturnType<typeof biChangeSorting>;
type BiChangeFiltering = ReturnType<typeof biChangeFiltering>;
type BiOpenReviewActions = ReturnType<typeof biOpenReviewActions>;
type BiOpenReplyActions = ReturnType<typeof biOpenReplyActions>;
type BiClickShowMyReview = ReturnType<typeof biClickShowMyReview>;
type BiSelectReviewAction = ReturnType<typeof biSelectReviewAction>;
type BiSelectReplyAction = ReturnType<typeof biSelectReplyAction>;
type BiReviewDeleted = ReturnType<typeof biReviewDeleted>;
type BiShowFilteredToZeroEmptyState = ReturnType<typeof biShowFilteredToZeroEmptyState>;
type BiShowZeroCommentsEmptyState = ReturnType<typeof biShowZeroReviewsEmptyState>;
type BiShowFailedToLoadEmptyState = ReturnType<typeof biShowFailedToLoadEmptyState>;
type BiShowFallbackState = ReturnType<typeof biShowFallbackState>;
type BiCloseMediaModal = ReturnType<typeof biCloseMediaModal>;
type BiOpenMediaModal = ReturnType<typeof biOpenMediaModal>;
type BiClickPaginationBtn = ReturnType<typeof biClickPaginationBtn>;
type BiClickShowAllReviewsBtn = ReturnType<typeof biClickShowAllReviewsBtn>;
type BiClickPublishReview = ReturnType<typeof biClickPublishReview>;
type BiFormValidationError = ReturnType<typeof biFormValidationError>;
type BiClickPublishReply = ReturnType<typeof biClickPublishReply>;
type BiCancelCreatingReview = ReturnType<typeof biCancelCreatingReview>;
type BiCancelCreatingReply = ReturnType<typeof biCancelCreatingReply>;
type BiReplyDeleted = ReturnType<typeof biReplyDeleted>;
type BiMediaUploaded = ReturnType<typeof biMediaUploaded>;
type BiMediaDeleted = ReturnType<typeof biMediaDeleted>;
type BiReplyPublishSuccess = ReturnType<typeof biReplyPublishSuccess>;
type BiShowPendingReviewModal = ReturnType<typeof biShowPendingReviewModal>;
type BiReviewSubmittedForApproval = ReturnType<typeof biReviewSubmittedForApproval>;
type BiClickClosePendingReviewModal = ReturnType<typeof biClickClosePendingReviewModal>;
type BiFocusFormBody = ReturnType<typeof biFocusFormBody>;

export type BiActions =
  | BiAppLoaded
  | BiAppIsInViewport
  | BiClickLeaveReviewBtn
  | BiReviewPublishSuccess
  | BiClickReplyBtn
  | BiClickHelpfulBtn
  | BiClickHelpfulBtnSuccess
  | BiClickShowMoreBtn
  | BiOpenSorting
  | BiOpenFiltering
  | BiChangeSorting
  | BiChangeFiltering
  | BiOpenReviewActions
  | BiOpenReplyActions
  | BiClickShowMyReview
  | BiSelectReviewAction
  | BiSelectReplyAction
  | BiReviewDeleted
  | BiShowFilteredToZeroEmptyState
  | BiShowZeroCommentsEmptyState
  | BiShowFailedToLoadEmptyState
  | BiShowFallbackState
  | BiCloseMediaModal
  | BiOpenMediaModal
  | BiClickPaginationBtn
  | BiClickShowAllReviewsBtn
  | BiClickPublishReview
  | BiFormValidationError
  | BiClickPublishReply
  | BiCancelCreatingReview
  | BiCancelCreatingReply
  | BiReplyDeleted
  | BiMediaUploaded
  | BiMediaDeleted
  | BiReplyPublishSuccess
  | BiShowPendingReviewModal
  | BiReviewSubmittedForApproval
  | BiClickClosePendingReviewModal
  | BiFocusFormBody;
