import { ControllerParams } from '@wix/yoshi-flow-editor';

export const normalizeError = (error: any) => {
  if (
    typeof Response !== 'undefined' &&
    error instanceof Response &&
    error.constructor.name !== 'Error'
  ) {
    return Error(`Failed to fetch ${error.url} with status ${error.status} - ${error.statusText}`);
  }
  return error;
};

export const decorateActionsWithLogger = <T extends Record<string, Function>>(
  actions: T,
  errorMonitor: ControllerParams['flowAPI']['errorMonitor'],
  logToConsole: boolean,
): T => {
  const decoratedActions: any = {};
  const decorate =
    (action: Function, key: string) =>
    (...args: any[]) => {
      const captureToSentry = (e: any) =>
        errorMonitor.captureException(normalizeError(e), {
          contexts: { action: { action: key } },
          tags: { capturedBy: 'action monitor' },
        });
      try {
        const response = action(...args);
        if (response instanceof Promise) {
          response.catch((error) => {
            if (error && error.status !== 200 && error.status !== 500) {
              return;
            }

            const eventId = captureToSentry(error);
            logToConsole && console.error(error, eventId);
          });
        }
        return response;
      } catch (error) {
        const eventId = captureToSentry(error);
        logToConsole && console.error(error, eventId);
      }
    };

  Object.keys(actions).forEach((key) => {
    const action = actions[key];
    decoratedActions[key] = decorate(action, key);
  });

  return decoratedActions;
};
