export const REVIEWS_APP_ID = 'a95a5fce-e370-4402-9ce4-96956acc747d';
export const REVIEWS_SERVER_APP_DEF_ID = '734e9983-9108-4d85-b8ce-bb19dd45b8cb';
export const RATINGS_SERVER_APP_DEF_ID = '71f92287-2ad9-402d-a4d6-d8369463f768';
export const ECOM_APP_DEF_ID = '1380b703-ce81-ff05-f115-39571d94dfcd';
export const BLOCKS_PRODUCT_PAGE_APP_DEF_ID = 'a0c68605-c2e7-4c8d-9ea1-767f9770e087';

export const REVIEWS_TEST_APP_SECTION_ID = '3dfd9303-8b9e-4607-9cae-13f257024c1d';
export const REVIEWS_STANDALONE_WIDGET_ID = 'eba2ec1c-4fd9-418b-b576-8095c384fc15';
export const REVIEWS_PLUGGABLE_WIDGET_ID = '5d58effa-91aa-443a-a7ec-c62e79543c37';
export const RATING_WIDGET_ID = '9c715d36-22f7-4633-b730-e73489729977';
export const RATING_LIST_WIDGET_ID = 'f8f77345-0446-478f-935d-38e3ff31df8e';
