export const STORES_APP_IDS = [
  // BLOCKS_APP_DEF_ID,
  'a0c68605-c2e7-4c8d-9ea1-767f9770e087',
  // Main app https://github.com/wix-private/wixstores-common/blob/576b9bf0dfe95887055b149178362c9a872ae694/packages/wixstores-client-core/src/constants.ts#LL1C34-L1C72
  '1380b703-ce81-ff05-f115-39571d94dfcd',
];

export const STORES_CATEGORY_PAGE_WIDGET_ID = 'bda15dc1-816d-4ff3-8dcb-1172d5343cce';

export const STORES_NAMESPACE = 'stores';
