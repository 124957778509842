import { noop } from 'lodash';
import { AppThunk } from '../store-types';
import { LoginModes } from '../../constants/login-modes';

export function requestLogin(mode?: LoginModes): AppThunk {
  return (dispatch, getState, { wixCodeApi }) => {
    const options: any = {
      lang: wixCodeApi.site.language,
      modal: true,
    };
    if (mode !== undefined) {
      options.mode = mode;
    }
    return wixCodeApi.user.promptLogin(options).catch(noop);
  };
}
