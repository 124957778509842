import {
  AuthorType as ServerAuthorType,
  Reply as ServerReply,
  ReviewContent as ServerReviewContent,
  Author as ServerAuthor,
  ListReviewsByEntityIdResponse,
  Type as ServerVoteType,
  ModerationStatus,
  EnrichedLiveSiteReview,
} from '@wix/ambassador-reviews-v1-enriched-live-site-review/types';
import { Member } from '../../../common/api-types';
import { ReviewContent } from '../../../common/store/reviews/reviews/review-state-types';
import { assertDefined, assertFields } from '~/ts-utils';
import type {
  Review,
  Reply,
  Author,
  FetchReviewsResponse,
  FetchReviewsResponseFull,
} from '../reviews-api-types';

export const fromServerReview = ({
  enrichedReview,
  currentMember,
  author,
}: {
  enrichedReview: EnrichedLiveSiteReview;
  currentMember?: Member;
  author?: Author;
}): Review => {
  const { review, currentUserVotes } = enrichedReview;
  assertDefined(review);
  assertFields(review, ['id', 'entityId', 'namespace'], 'review');
  return {
    content: fromServerReviewContent(review.content),
    resourceId: review.entityId,
    id: review.id,
    createdDate: (review.reviewDate || review.createdDate || new Date(Date.now())).toISOString(),
    author: author ?? fromServerAuthor(review.author, currentMember),
    reply: review.reply ? fromServerReply(review.reply) : undefined,
    foundHelpful: review.foundHelpful ?? 0,
    foundUnhelpful: review.foundUnhelpful ?? 0,
    helpfulness: review.helpfulness ?? 0,
    currentUserVote:
      currentUserVotes?.id && currentUserVotes?.type
        ? {
            type: currentUserVotes.type === ServerVoteType.UPVOTE ? 'UPVOTED' : 'DOWNVOTED',
            voteId: currentUserVotes.id,
          }
        : { type: 'NEUTRAL' },
    moderationStatus: review.moderation?.moderationStatus ?? 'UNKNOWN',
    namespace: review.namespace,
    verified: review.verified ?? false,
  };
};

export const fromServerReviewContent = (
  content: ServerReviewContent | undefined,
): ReviewContent => {
  return {
    title: content?.title ?? undefined,
    body: content?.body ?? undefined,
    rating: content?.rating || 0,
    media: (content?.media as any) || [],
  };
};

export const toServerReviewContent = (content: ReviewContent): ServerReviewContent => {
  return {
    title: content.title,
    body: content.body,
    rating: content.rating || 0,
    media: content.media.map((m) => {
      if ('image' in m) {
        return { image: { id: m.image.id } };
      } else {
        return { video: { id: m.video.id } };
      }
    }),
  };
};
export const fromServerAuthor = (
  author: ServerAuthor | undefined,
  currentMember?: Member,
): Author => {
  if (
    !author ||
    !author.authorType ||
    author.authorType === ServerAuthorType.UNKNOWN ||
    !author.contactId
  ) {
    return { type: 'UNKNOWN' };
  }

  if (
    currentMember &&
    author.authorType === ServerAuthorType.MEMBER &&
    currentMember.contactId === author.contactId
  ) {
    // Its for newly created reviews which did not go through reviews-enricher
    return {
      type: 'MEMBER',
      id: author.contactId,
      name: currentMember.profile?.nickname || undefined,
    };
  }

  return {
    type: author.authorType,
    id: author.contactId,
    name: author.authorName || undefined,
  };
};

export const fromServerReply = (reply: ServerReply): Reply => {
  return {
    content: reply.message || '',
    createdDate: (reply.createdDate as any) || new Date(Date.now()).toISOString(),
  };
};

export const fromServerListResponse = (
  response: ListReviewsByEntityIdResponse,
): FetchReviewsResponse => ({
  reviews: (response.reviews ?? []).map((enrichedReview) =>
    fromServerReview({
      enrichedReview,
    }),
  ),
  metadata: {
    cursors: {
      next: response?.metadata?.cursors?.next ?? undefined,
      prev: response?.metadata?.cursors?.prev ?? undefined,
    },
  },
});

// The summary server gets out of sync with the reviews server, so we
// need to make sure the count is not smaller than the total number
// of reviews. Its only possible when list is fetched without any filters.
// TODO: handle more edge cases/extract to fn/check how it'll go with moderation/test
// should we do these fixes when going from deepLink -> fullList state?
// also this breakdown is adjust with pending review later on, all these adjustments could be ideally done in single place
export const fixInconsistentRatingsBreakdown = (
  response: FetchReviewsResponseFull,
): FetchReviewsResponseFull => {
  const ratingsBreakdown = [1, 2, 3, 4, 5].reduce<Record<number, number>>((acc, rating) => {
    const summaryValue = response.ratingsBreakdown[rating];
    // only count approved reviews
    const valueFromList = response.reviews.filter(
      (r) => r.content.rating === rating && r.moderationStatus === ModerationStatus.APPROVED,
    ).length;

    acc[rating] = Math.max(summaryValue, valueFromList);
    return acc;
  }, {});
  return {
    ...response,
    ratingsBreakdown,
  };
};

export const fromServerListResponseFull = (
  response: ListReviewsByEntityIdResponse,
): FetchReviewsResponseFull => ({
  ...fromServerListResponse(response),
  permissions: response?.additionalData?.permissions || [],
  ratingsBreakdown: [1, 2, 3, 4, 5].reduce<Record<number, number>>((acc, rating) => {
    acc[rating] =
      response?.additionalData?.overallRatingSummary?.valueBreakdown?.find(
        (v) => v.value === rating,
      )?.total ?? 0;
    return acc;
  }, {}),
  configuration: response?.additionalData?.config,

  currentMember: response?.additionalData?.currentMember,
  currentUserReview: response?.additionalData?.currentUserReview
    ? fromServerReview({
        enrichedReview: response.additionalData.currentUserReview,
      })
    : undefined,
});
