import { StateWrapper } from '../../common/common-types';
import { ReduxStore } from '../create-redux-store';
import { createPatch } from './create-patch';

export function createStateOptimizer(store: ReduxStore, onChange: (update: StateWrapper) => void) {
  let isDisabled = false;
  let patchNumber = 0;
  let prevState = store.getState();

  const listener = () => {
    const nextState = store.getState();
    if (nextState === prevState) {
      return;
    }

    if (isDisabled) {
      onChange({ type: 'SET', state: store.getState() });
      return;
    }
    patchNumber++;
    const patch = createPatch(prevState, nextState);
    onChange({ type: 'PATCH', patchNumber, patch });
    prevState = nextState;
  };

  return {
    disable: () => (isDisabled = true),
    subscribe: () => store.subscribe(listener),
    requestFullState: () => {
      patchNumber = 0;
      onChange({ type: 'SET', state: store.getState() });
    },
  };
}
